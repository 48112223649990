<template>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <div class="b-logos-stripe">
               <img class="b-logos-stripe__item" src="./../../public/net-id.svg" alt="netId">
               <img class="b-logos-stripe__item" src="./../../public/net-id.svg" alt="netId">
               <img class="b-logos-stripe__item" src="./../../public/net-id.svg" alt="netId">
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   export default {
   }
</script>

<style lang="less">
   .b-logos-stripe {
      padding: 20px 0;
      .flex-row-space-between;

      &__item {
         height: 25px;
         width: auto;
      }
   }
</style>
