<template>
   <ul class="b-list">
      <li v-for="(item, index) in items" :key="index" class="b-list__item">
         <font-awesome-icon icon="check" class="b-list__tick" />

         <div class="b-list__text">
            {{ item.text }}
         </div>

         <img 
            v-if="item.icon"
            class="b-list__icon"  
            :src="item.icon"
            :alt="item.text">
      </li>
   </ul>
</template>

<script>
   export default {
      props: {
         items:{},
      },
   }
</script>

<style lang="less">
   .b-list {
      --size: @as-font-size-regular;

      &__item {
         margin: 7px 0;
         .flex-row-left-center;
      }

      &__text {
          font-size: var(--size);
      }

      &__tick {
         font-size: calc(var(--size)*1.1);
         margin-right: 8px;
         color: @as-green;
      }

      &__icon {
         margin-left: 20px;
         width: calc(var(--size) * 2.4);
         height: auto;
         color: @as-dark-blue;
      }
   }
</style>
