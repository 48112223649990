export const env = {
   //API_BASE_URL: 'http://asuro-api.test/api/', //local API
    API_BASE_URL: 'https://asuro-api.design-it.systems/api/', //staging API
   ASURO_API_CLIENT_ID: '',
   ASURO_API_CLIENT_SECRET: '',
};

/* TODO: replace this id for the cyberInsurance insurer */
export const CYBER_INSURANCE_ID = 88;
export const ASURO_WEB_INSURANCE_MANAGER_URL = 'https://asuro-web.design-it.systems/login';
