<template>
   <div id="app" class="asuro-webapp">
      <TopBar />
      <router-view />
   </div>
</template>

<script>
    // @ is an alias to /src
    import TopBar from '@/components/TopBar.vue';

    export default {
       name: 'Home',
       components: {
          TopBar,
       },
    };
</script>

<style lang="less">
   //
</style>