import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../pages/Home.vue';

Vue.use(VueRouter);

const routes = [
   {
      path: '/',
      name: 'Home',
      component: Home
   },
   {
      path: '/consent',
      name: 'Consent',
      component: () => import(/* webpackChunkName: "about" */ '../pages/Consent.vue')
   },
   {
      path: '/success',
      name: 'Success',
      component: () => import(/* webpackChunkName: "about" */ '../pages/Success.vue')
   },
   {
      path: '/error',
      name: 'Error',
      component: () => import(/* webpackChunkName: "about" */ '../pages/Error.vue')
   }

];

const router = new VueRouter({
   routes
});

export default router;
