import Vue from 'vue';
import App from './App.vue';
import 'bootstrap';
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhoneSquareAlt, faBars, faCopyright, faCheck, faIdCard, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPhoneSquareAlt, faBars, faCopyright, faCheck, faIdCard, faFilePdf)

/** @see https://kazupon.github.io/vue-i18n/ */
import VueI18n from 'vue-i18n';
import locales from './i18n/index.js';
import router from './router';

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue);
Vue.config.productionTip = false;

// Enable translations
Vue.use(VueI18n);

const i18n = new VueI18n({
   locale: 'de-DE',
   // locale: 'en-EN',
   messages: locales
});

Vue.i18n = i18n;

new Vue({
   router,
   render: h => h(App)
}).$mount('#app')
