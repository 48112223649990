<template>
   <div class="b-top-bar">
      <div class="b-top-bar__inner">
         <img class="b-top-bar__logo" src="./../../public/asuro_iso_white.svg" alt="asuro">

         <div class="b-top-bar__content">
            <div class="b-top-bar__title">
               Versicherungsmanager
            </div>

            <div class="b-top-bar__text">
               Empfohlen von <strong>GMX</strong>
            </div>
         </div>

         <font-awesome-icon icon="phone-square-alt" class="b-top-bar__icon" />
      </div>
   </div>
</template>

<script>
   export default {
      props: {
      }
   }
</script>

<style lang="less">
   .b-top-bar {
      --logo-width: 40px;

      background-color: @as-dark-blue;
      padding: 13px 20px;
      color: white;

      &__inner {
         .flex-row-left-top;
         max-width: @mobile;
         margin-right: auto;
         margin-left: auto;
      }

      &__logo {
          margin-right: calc(var(--logo-width) / 4);
          width: var(--logo-width);
          height: auto;
      }

      &__content {
          .flex-grow(1);
      }

      &__title {
          line-height: 1;
          font-size: 25px;
          font-weight: 300;
          margin-bottom: 7px;
      }

      &__text {
         line-height: 1;

         strong {
            font-weight: 900;
         }
      }

      &__icon {
         font-size: 25px;
      }
   }
</style>
