<template>
   <section class="p-landing">
      <div class="asuro-webapp__content">
         <div class="container">
            <div class="row">
               <div class="col-sm-12">
                  <div class="h1">
                     AKTION: Cyberschutz<br>12 Monate kostenlos!
                  </div>

                  <div class="h3">
                     Bis zu 15.000 € Schadenersatz bei
                  </div>
               </div>

               <div class="col-sm-12">
                  <List :items="listItems" />
               </div>

               <div class="col-sm-12 mt-20">
                  Kein Risiko: Vertrag endet automatisch!
               </div>

               <div class="col-sm-12">
                  <div class="pe-pdf-download">
                     <font-awesome-icon icon="file-pdf" class="pe-pdf-download__icon" />
                     alle Versicherungsleistungen im Überblick
                  </div>
               </div>
            </div>
         </div>

         <div class="container pe-bottom-text">
            <div class="row">
               <div class="col-sm-12">
                  <strong>Kostenlos, übersichtlich & bequem:</strong> <br>
                  Die Police finden Sie nach Aktivierung in Ihrem asuro Versicherungsmanager.
               </div>
            </div>
         </div>

         <LogosStripe />
      </div>

      <div class="asuro-webapp__footer">
         <b-overlay
            :show="isButtonSpinnerVisible"
            spinner-medium
            opacity="0.2"
         >
            <a
               :href="authenticateEndpoint"
               class="btn btn-green is-full"
               @click="isButtonSpinnerVisible = true"
            >
               Weiter mit GMX Login
            </a>
         </b-overlay>
      </div>
   </section>
</template>

<script>
   import { v4 as uuidv4 } from 'uuid';
   import UAParser from 'ua-parser-js';

   import { env } from '../config/constants.js';

   import List from '@/components/List.vue'
   import LogosStripe from '@/components/LogosStripe.vue'

   export default {
      name: 'Home',
      components: {
         List,
         LogosStripe,
      },
      data() {
         return {
            listItems: [
               { text:'Kreditkartenbetrug', icon: 'icon_savemoney_darkblue.svg'},
               { text:'Onlinebanking-Betrug', icon: 'icon_savemoney_darkblue.svg'},
               { text:'Identitätsdiebstahl', icon: 'icon_savemoney_darkblue.svg'},
            ],
            isButtonSpinnerVisible: false,
         }
      },
      computed: {
         authenticateEndpoint() {
            return `${env.API_BASE_URL}v9/authenticate${this.deviceQueryString}`;
         },
         deviceQueryString() {
            return `?uuid=${this.device.uuid}&platform=${this.device.platform}&version=${this.device.version}&manufacturer=${this.device.manufacturer}&model=${this.device.model}`;
         },
         device() {
            var parser = new UAParser();
            const browser = parser.getBrowser();
            const deviceInfo = parser.getDevice();
            const os = parser.getOS();

            const device = {
               uuid: localStorage.getItem('uuid'),
               model: deviceInfo.model ? deviceInfo.model : os.version,
               version: browser.version,
               manufacturer: browser.name,
               platform: os.name,
            };

            return device;
         }
      },
      created() {
         if (!localStorage.getItem('uuid')) {
            localStorage.setItem('uuid', uuidv4());
         }
      }
   };
</script>

<style lang="less">
   .p-landing {
      text-align: center;
      padding-top: var(--page-top-padding) !important;

      .b-list {
         display: inline-block;
      }

      .pe-pdf-download {
         cursor: pointer;
         margin-top: 30px;
         .flex-row-left-center;
         .flex-display(inline-flex);

         &__icon {
            font-size: 30px;
            color: @as-light-blue;
            margin-right: 8px;
         }
      }

      .pe-bottom-text {
         margin-top: 20px;
         background-color: @as-lighter-grey;
         padding: 15px 0;
         line-height: 1.2;
      }
   }
</style>

